import { Badge, Button, Form, Tabs, Tooltip } from 'antd';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaSpinner } from 'react-icons/fa';
import { useMutation } from 'react-query';

import { useActiveReportingPeriod } from '../../../../../hooks/useActiveReportingPeriod';
import {
  MaterialComposition,
  Packaging,
  SeparableItem,
} from '../../../../../types';
import { savePackaging } from '../../packagings.api';
import Composition, { compositionFields } from './Composition';
import EvidenceAudit from './EvidenceAudit';
import Overview from './Overview';

const { TabPane } = Tabs;

interface PackagingEditorProps {
  editedRecord?: Packaging;
  readOnly: boolean;
  updateEditedRecord: (data: Packaging) => void;
  handleClose: () => void;
}
type tabName = 'noChange' | 'overview' | 'items' | 'evidence';

const isCompositionValid = (
  composition = {} as MaterialComposition,
  isPrimaryMaterial = false,
) => {
  const firstCheck =
    (isPrimaryMaterial || !!composition.description) &&
    !!composition.material &&
    composition.weightInGrams > 0;

  const secondCheck =
    !composition.description &&
    !composition.material &&
    !composition.weightInGrams &&
    composition.weightInGrams !== 0;

  const result = isPrimaryMaterial ? firstCheck : firstCheck || secondCheck;

  return result;
};

export const verifyComposition = (items = [{}] as SeparableItem[]) =>
  items.every((item) =>
    compositionFields.every((level) =>
      isCompositionValid(
        item?.[level] as MaterialComposition,
        level === 'primaryMaterial',
      ),
    ),
  );

//TODO: trigger general form and separable forms save separately

const PackagingEditor = ({
  editedRecord = {} as Packaging,
  updateEditedRecord,
  readOnly,
  handleClose,
}: PackagingEditorProps) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('overview');

  const { reportingPeriod } = useActiveReportingPeriod();

  function areCompositionsValid() {
    const result = verifyComposition(editedRecord.separableItems);
    return result;
  }

  //TODO: do we need setID?
  const { isLoading: isSaving, mutate } = useMutation(savePackaging, {
    onSuccess: (savedData: any) => {
      updateEditedRecord(savedData);
    },
  });

  const handleSave = (data: Partial<Packaging>) => {
    if (!isSaving) {
      const newData = {
        ...data,
        _id: editedRecord._id,
        reportingPeriodId: reportingPeriod?._id,
      };

      // console.log('isSaving', isSaving);
      // console.log('data: ', newData, 'editedREcord', editedRecord);

      mutate(newData);
    }
  };

  return (
    <div className="p-4 bg-white">
      <header className="flex items-center">
        <div className="mr-auto font-sans text-2xl font-bold md:text-3xl">
          {editedRecord.title || 'Packaging editor'}{' '}
          {readOnly ? (
            <Tooltip
              title={t('packagingEditor.toolTipTxt')}
              placement="right"
              autoAdjustOverflow
            >
              <Badge
                count="Read only mode"
                className="ml-1"
                style={{ backgroundColor: 'lightgray' }}
                title=""
              />
            </Tooltip>
          ) : (
            ''
          )}
        </div>
        <Button
          type="primary"
          className="font-bold text-white uppercase"
          onClick={handleClose}
        >
          {t('packagingEditor.btnTxt')}
        </Button>
      </header>
      <main>
        <Tabs
          activeKey={activeTab}
          onChange={(key) => {
            setActiveTab(key);
          }}
        >
          <TabPane tab={t('packagingEditor.tabPane-1')} key="overview">
            <Overview
              editedRecord={editedRecord}
              readOnly={readOnly}
              tab={activeTab}
              handleSave={handleSave}
            />
          </TabPane>
          <TabPane
            disabled={!editedRecord._id}
            tab={
              <span
                className={
                  areCompositionsValid()
                    ? ''
                    : 'text-white p-1 rounded-lg bg-red-500'
                }
              >
                {t('packagingEditor.tabPane-2')}
              </span>
            }
            key="items"
          >
            <Composition
              items={editedRecord.separableItems}
              readOnly={readOnly}
              handleSave={updateEditedRecord}
              tab={activeTab}
              packagingId={editedRecord?._id}
            />
          </TabPane>
          <TabPane
            disabled={!editedRecord._id}
            tab={t('packagingEditor.tabPane-3')}
            key="evidence"
          >
            <EvidenceAudit packaging={editedRecord} readOnly={readOnly} />
          </TabPane>
        </Tabs>
      </main>
      {isSaving ? (
        <div
          className="fixed flex items-center justify-center text-center text-gray-600 rounded-full"
          style={{
            bottom: '50px',
            right: '50px',
          }}
        >
          <FaSpinner className="riz-spinner" size={20} />
          <span className="ml-1 text-sm font-semibold">
            {t('packagingEditor.saving')}
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default PackagingEditor;
