import { InfoCircleFilled } from '@ant-design/icons';
import {
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Popover,
  Row,
  Select,
  message,
} from 'antd';
import { debounce } from 'lodash';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { PackagingItemsService } from '../../../../../api/services';
import { Packaging, SeparableItem } from '../../../../../types';
import { getNEAMaterialTypesMap } from '../../../../../utils';

interface CompositionProps {
  items: SeparableItem[];
  tab: string;
  packagingId: string;
  readOnly: boolean;
  handleSave: (packaging: Packaging) => void;
}

export const compositionFields = [
  'primaryMaterial',
  'secondaryMaterial1',
  'secondaryMaterial2',
  'secondaryMaterial3',
  'secondaryMaterial4',
  'secondaryMaterial5',
] as (keyof SeparableItem)[];
const subFields = ['description', 'material', 'weightInGrams'];

const Composition = ({
  items = [],
  tab,
  readOnly,
  handleSave,
  packagingId,
}: CompositionProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const patchSeparableItem = (itemId: string, update: any) => {
    if (readOnly) {
      return;
    }
    message.loading({
      content: 'Saving...',
      duration: 0,
      key: 'packaging-items-save',
    });
    PackagingItemsService.patch(itemId, update)
      .then(
        (res: SeparableItem) => {
          const newItems = items.map((item) =>
            item._id === itemId ? res : item,
          );
          message.success({
            content: 'Saved',
            key: 'packaging-items-save',
            duration: 1,
          });
          handleSave({ separableItems: newItems } as Packaging);
          queryClient.invalidateQueries(['audit-trail', packagingId]);
        },
        (err: Error) => {
          message.error(t('packagingEditor.composition.savingErr'));
          console.log('Error in saving composition: ', err);
        },
      )
      .finally(() => {
        form.validateFields();
      });
  };
  const handleFormChange = (changes = {} as any, all = {} as any) => {
    if (readOnly) {
      return;
    }
    const [itemId, itemLevel = {} as any] = Object.entries(changes)[0];
    const [levelName, level = {} as any] = Object.entries(itemLevel)[0];
    const [fieldName] = Object.entries(level)[0];

    if (fieldName) {
      form
        .validateFields(subFields.map((item) => [itemId, levelName, item]))
        .then(
          (values) => {
            const [_id, data] = Object.entries(values)[0];
            patchSeparableItem(itemId, data);
          },
          (e) => {
            console.log('Fields invalid: ', e);
            const { values } = e;
            const [_id, data] = Object.entries(values)[0];
            patchSeparableItem(itemId, data);
          },
        );
    }
  };
  const getItemRules = (path: string[]) => {
    const allFieldNames = ['weightInGrams', 'material', 'description'];
    const [_id, level] = path;
    const values = items.find((item) => item._id === _id) || {};
    // @ts-ignore
    const { [level]: composition = {} } = values;
    return allFieldNames.some((item) => !!composition[item]);
  };

  useEffect(() => {
    form.setFieldsValue(
      items.reduce(
        (acc, curr) => ({
          ...acc,
          [curr._id]: compositionFields.reduce(
            (all, field) => ({
              ...all,
              [field]: {
                //@ts-ignore
                ...(curr?.[field] || {}),
                ...(field === 'primaryMaterial'
                  ? { description: curr?.materialItem?.name }
                  : {}),
              },
            }),
            {},
          ),
        }),
        {},
      ),
    );
    form.validateFields();
  }, [tab]);

  return (
    <Form
      form={form}
      onValuesChange={debounce(handleFormChange, 500)}
      layout="horizontal"
    >
      {items.map((item) => (
        <div key={item._id}>
          <div className="flex justify-between mb-4">
            <div className="text-lg font-bold capitalize">
              {item.category} {item?.materialItem?.name}
              {item.description ? ` (${item.description})` : null}
            </div>
          </div>
          <Row gutter={16} className="mb-2">
            <Col span={4}>
              {t('packagingEditor.composition.primaryMaterial')}
            </Col>
            <Col span={4} className="align-btn-icon">
              {t('packagingEditor.composition.Secondary-material-1')}
              <Popover
                title={
                  <div className="font-bold">
                    {t('packagingEditor.composition.popTitle')}
                  </div>
                }
                content={
                  <div style={{ maxWidth: '300px' }}>
                    <p>{t('packagingEditor.composition.popTitle')}</p>
                    <div>{t('packagingEditor.composition.popText-1')}</div>
                    <ul style={{ listStyleType: 'disc', marginLeft: '16px' }}>
                      <li>
                        {t('packagingEditor.composition.theLDPELabelAnd')}
                      </li>
                      <li>
                        {t('packagingEditor.composition.theHDPENeckRing')}
                      </li>
                    </ul>
                    <div>{t('packagingEditor.composition.primary_text')}</div>
                  </div>
                }
                placement="right"
                autoAdjustOverflow
              >
                <InfoCircleFilled
                  className="ml-2 cursor-pointer"
                  style={{ color: '#92d050' }}
                />
              </Popover>
            </Col>
            <Col span={4}>
              {t('packagingEditor.composition.Secondary-material-2')}
            </Col>
            <Col span={4}>
              {t('packagingEditor.composition.Secondary-material-3')}
            </Col>
            <Col span={4}>
              {t('packagingEditor.composition.Secondary-material-4')}
            </Col>
            <Col span={4}>
              {t('packagingEditor.composition.Secondary-material-5')}
            </Col>
          </Row>
          <Form.Item name={[item._id, '_id']} hidden noStyle>
            <Input />
          </Form.Item>
          <Row gutter={16}>
            {compositionFields.map((field) => (
              <Col span={4} key={field}>
                <Form.Item
                  name={[item._id, field, 'description']}
                  key={`${item._id}.${field}.description`}
                  rules={[
                    {
                      message: '',
                      required: getItemRules([item._id, field]),
                    },
                  ]}
                >
                  <Input
                    className={field === 'primaryMaterial' ? 'capitalize' : ''}
                    placeholder={t('packagingEditor.composition.desc')}
                    disabled={field === 'primaryMaterial'}
                    allowClear
                  />
                </Form.Item>
                <Form.Item
                  name={[item._id, field, 'material']}
                  rules={[
                    {
                      message: '',
                      required: getItemRules([item._id, field]),
                    },
                  ]}
                >
                  <Select
                    placeholder={t('packagingEditor.composition.material')}
                    showSearch
                    allowClear
                  >
                    {Array.from(getNEAMaterialTypesMap()).map(
                      ([category, subcategories]) => (
                        <Select.OptGroup
                          label={<span className="capitalize">{category}</span>}
                          key={category}
                        >
                          {subcategories.map((subcategory: string) => (
                            <Select.Option
                              key={`${category} - ${subcategory}`}
                              value={`${category} - ${subcategory}`}
                            >
                              {subcategory}
                            </Select.Option>
                          ))}
                        </Select.OptGroup>
                      ),
                    )}
                  </Select>
                </Form.Item>
                <Form.Item
                  name={[item._id, field, 'weightInGrams']}
                  rules={[
                    {
                      message: '',
                      required: getItemRules([item._id, field]),
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    min={0.001}
                    placeholder={t('packagingEditor.composition.wInGrams')}
                  />
                </Form.Item>
              </Col>
            ))}
          </Row>
          <Divider />
        </div>
      ))}
    </Form>
  );
};

export default Composition;
