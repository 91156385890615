import { InfoCircleFilled } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Select,
  Space,
  Switch,
  message,
} from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { NamePath } from 'antd/lib/form/interface';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsTrash } from 'react-icons/bs';

import { PackagingItemsService } from '../../../../api/services';
import { useAuth } from '../../../../hooks/useAuth';
import {
  BulkPackaging,
  BulkSeparableItem,
  CategoryType,
  MaterialItem,
} from '../../../../types';
import {
  PackagingStatuses,
  getAccountTitle,
  getNEACategories,
  getNEAMaterialTypesMap,
  getRandomAlphaNumericString,
} from '../../../../utils';
import PackagingComments from './Comments';
import PackagingEvidenceFiles from './Evidence';
import { useAssessmentMethods } from './useAssessmentMethods';

type CategoryState = {
  [category in CategoryType]: MaterialItem[];
};

interface OverviewProps {
  form: FormInstance;
  itemsForm: FormInstance;
  editedRecord: BulkPackaging;
  readOnly: boolean;
  handleSave: (items: Partial<BulkPackaging>) => void;
  handleInfoChanges: (item: Partial<BulkPackaging>) => void;
  tab: string;
}

//FIXME: packaging items not disappear from items tab on delete

const getEmptySeparableItem = () =>
  ({
    _id: `NEW-${getRandomAlphaNumericString(20)}`,
    description: undefined,
    category: undefined,
    neaPackagingForm: undefined,
    weight: { value: undefined, unit: 'kg' },
    isBulkPackaging: true,
    isReportable: true,
  } as unknown as BulkSeparableItem);

const Overview = ({
  editedRecord,
  readOnly,
  form,
  itemsForm,
  tab,
  handleSave,
  handleInfoChanges,
}: OverviewProps) => {
  const { t } = useTranslation();
  const auth = useAuth();

  const [state, setState] = useState({
    isLoading: false,
    items: [] as BulkSeparableItem[],
    showEvidenceNeededInfo: false,
  });

  const othersChecked = !!form.getFieldValue('otherSource');
  // console.log('Check: ', othersChecked);

  const addItem = () => {
    const newItem = getEmptySeparableItem();
    const { _id, ...rest } = newItem;
    itemsForm.setFieldsValue({ [_id]: rest });
    setState((old) => ({
      ...old,
      items: [...old.items, newItem],
    }));
  };
  const removeItem = (itemId: string) => {
    if (readOnly) {
      return;
    }
    if (itemId.startsWith('NEW')) {
      setState((old) => ({
        ...old,
        items: old.items.filter((item) => item._id !== itemId),
      }));
    } else {
      PackagingItemsService.remove(itemId).then(
        () => {
          handleSave({
            separableItems: (editedRecord?.separableItems || []).filter(
              (item) => item._id !== itemId,
            ),
          });
          setState((old) => ({
            ...old,
            items: old.items.filter((item) => item._id !== itemId),
          }));
        },
        (error: Error) => {
          message.error(t('packagingEditor.overview.removeErr'));
          console.log('Error in removing packaging item: ', error);
        },
      );
    }
  };
  const handleItemsChange = (changes: any, allFields: any) => {
    if (readOnly) {
      return;
    }
    if (editedRecord._id) {
      const [fieldId = ''] = Object.entries(changes)?.[0];
      const mustBeValid = Object.keys(getEmptySeparableItem()).map(
        (fieldName) => [fieldId, fieldName],
      );
      itemsForm.validateFields(mustBeValid).then(
        (values) => {
          const { _id, ...rest } = Object.values(
            values,
          )[0] as BulkSeparableItem;
          if (fieldId.startsWith('NEW')) {
            PackagingItemsService.create({
              ...rest,
              packagingId: editedRecord._id,
            }).then(
              (res: BulkSeparableItem) => {
                handleSave({
                  separableItems: [
                    ...(editedRecord?.separableItems || []),
                    res,
                  ],
                });
                itemsForm.setFieldsValue({ [res._id]: res });
                setState((old) => ({
                  ...old,
                  items: old.items.map((item) =>
                    item._id === fieldId ? res : item,
                  ),
                }));
              },
              (e: Error) => {
                console.log('Error in creating packaging item', e);
                message.error(t('packagingEditor.overview.creatingErr'));
              },
            );
          } else {
            PackagingItemsService.patch(fieldId, rest).then(
              (res: BulkSeparableItem) => {
                handleSave({
                  separableItems: (editedRecord.separableItems || []).map(
                    (item) => (item._id === res._id ? res : item),
                  ),
                });
                setState((old) => ({
                  ...old,
                  items: old.items.map((item) =>
                    item._id === res._id ? res : item,
                  ),
                }));
              },
              (error: Error) => {
                console.log('Error in saving packaging item: ', error);
                message.error(t('packagingEditor.overview.savingErr'));
              },
            );
          }
        },
        () => console.log('Field invalid to quick save'),
      );
    } else {
      handleInfoChanges({});
    }
  };
  const getSelectedCategory = (
    path: string[],
    getValueFc: (name: NamePath) => any,
  ) => {
    const selectValue = getValueFc(path) || '';
    const [category] = selectValue.split(' - ') as string[];
    return category.toLowerCase() as CategoryType;
  };
  const handleCategoryChange = (itemId: string) => {
    const value = itemsForm.getFieldsValue([itemId]) || {};
    itemsForm.setFieldsValue({
      [itemId]: {
        ...value,
        neaPackagingForm: undefined,
      } as BulkSeparableItem,
    });
  };

  const [focusedIndex, setFocusedIndex] = useState<undefined | number>(
    undefined,
  );

  const { data: methodologies } = useAssessmentMethods();
  useEffect(() => {
    setState((old) => ({ ...old, isLoading: true }));

    const {
      _id,
      title,
      description,
      statuses = [],
      assessmentComment,
      assessmentMethodId,
      originalSources,
      otherSource,
    } = editedRecord;
    form.setFieldsValue({
      _id,
      title,
      description,
      statuses,
      assessmentComment,
      assessmentMethodId,
      originalSources,
      otherSource,
    });
  }, [tab]);

  useEffect(() => {
    const { separableItems = [getEmptySeparableItem()] } = editedRecord;
    const items = separableItems.reduce(
      (acc, curr) => ({ ...acc, [curr._id]: curr }),
      {},
    );
    itemsForm.setFieldsValue(items);
    setState((old) => ({ ...old, items: separableItems }));
  }, [editedRecord._id]);

  return (
    <>
      <section>
        <Row gutter={16}>
          <Col span={editedRecord._id ? 14 : 24}>
            <Form
              layout="vertical"
              form={form}
              name="packaging-form"
              onValuesChange={debounce(handleInfoChanges, 500)}
              requiredMark={false}
            >
              <Form.Item name="_id" hidden noStyle>
                <Input />
              </Form.Item>
              <Row gutter={16}>
                <Col span={16}>
                  <Form.Item
                    label={t('packagingEditor.overview.form.name')}
                    name="title"
                    rules={[
                      {
                        required: true,
                        message: t('packagingEditor.overview.form.nameMsg'),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t(
                        'packagingEditor.overview.form.namePlaceholder',
                      )}
                    />
                  </Form.Item>
                  <Form.Item
                    label={t('packagingEditor.overview.form.desc')}
                    name="description"
                  >
                    <Input.TextArea
                      placeholder={t(
                        'packagingEditor.overview.form.descPlaceholder',
                      )}
                      rows={7}
                      style={{ resize: 'none' }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={t('packagingEditor.overview.form.status')}
                    labelCol={{ span: 24 }}
                    name="statuses"
                  >
                    <Checkbox.Group
                      onChange={(checkedValues: CheckboxValueType[]) => {
                        if (
                          checkedValues.length === 0 ||
                          (checkedValues.length === 1 &&
                            checkedValues.includes('redFlag'))
                        ) {
                          setState((old) => ({
                            ...old,
                            showEvidenceNeededInfo: false,
                          }));
                        } else {
                          setState((old) => ({
                            ...old,
                            showEvidenceNeededInfo: true,
                          }));
                        }
                      }}
                    >
                      <Space direction="vertical">
                        {PackagingStatuses.map((item) => (
                          <Checkbox key={item.value} value={item.value}>
                            {item.label}
                          </Checkbox>
                        ))}
                        {state.showEvidenceNeededInfo && (
                          <p className="text-red">
                            Please make sure that details for proxies and
                            assumptions are provided in the evidence section
                            below
                          </p>
                        )}
                      </Space>
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
          {editedRecord._id ? (
            <Col span={10}>
              <PackagingComments packagingId={editedRecord?._id} />
            </Col>
          ) : null}
        </Row>
      </section>

      <section className="mt-5">
        <Row gutter={16} className="mb-2">
          <Col span={7}>{t('packagingEditor.overview.description')}</Col>
          <Col span={5}>{t('bulkPackaging.Overview.materialCateg')}</Col>
          <Col span={5}>{t('packagingEditor.overview.neaPkg')}</Col>
          <Col span={4} className="align-btn-icon">
            {t('bulkPackaging.Overview.wInKGs')}
          </Col>
          <Col span={2}>{t('Reportable')}</Col>
        </Row>
        <Form
          form={itemsForm}
          onValuesChange={debounce(handleItemsChange, 500)}
          scrollToFirstError
        >
          {state.items.map(({ _id }, index) => (
            <Row gutter={16} key={_id}>
              <Form.Item name={[_id, '_id']} hidden noStyle>
                <Input />
              </Form.Item>
              <Form.Item
                name={[_id, 'isBulkPackaging']}
                valuePropName="checked"
                hidden
                noStyle
              >
                <Checkbox />
              </Form.Item>
              <Col span={7}>
                <Form.Item
                  className="mb-1"
                  name={[_id, 'description']}
                  rules={[
                    {
                      required: true,
                      message: t('bulkPackaging.Overview.descriptionReq'),
                    },
                  ]}
                >
                  <Input
                    autoFocus
                    placeholder={t('bulkPackaging.Overview.enterDescription')}
                  />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item
                  className="mb-0"
                  name={[_id, 'category']}
                  rules={[
                    {
                      required: true,
                      message: t('packagingEditor.overview.form.categoryMsg'),
                    },
                  ]}
                >
                  <Select
                    placeholder={t('packagingEditor.composition.material')}
                    showSearch
                    allowClear
                    onChange={() => handleCategoryChange(_id)}
                  >
                    {Array.from(getNEAMaterialTypesMap()).map(
                      ([category, subcategories]) => (
                        <Select.OptGroup
                          label={<span className="capitalize">{category}</span>}
                          key={category}
                        >
                          {subcategories.map((subcategory: string) => (
                            <Select.Option
                              key={`${category} - ${subcategory}`}
                              value={`${category} - ${subcategory}`}
                            >
                              {subcategory}
                            </Select.Option>
                          ))}
                        </Select.OptGroup>
                      ),
                    )}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={5}>
                <Form.Item className="mb-1" shouldUpdate>
                  {({ getFieldValue }) => (
                    <Form.Item
                      className="mb-0"
                      name={[_id, 'neaPackagingForm']}
                      rules={[
                        {
                          required: true,
                          message: t('packagingEditor.overview.form.neaMsg'),
                        },
                      ]}
                      noStyle
                    >
                      <Select
                        placeholder={t('packagingEditor.overview.form.nea')}
                        showSearch
                      >
                        {getNEACategories(
                          getSelectedCategory([_id, 'category'], getFieldValue),
                        ).map((item: string) => (
                          <Select.Option
                            key={item.toLowerCase()}
                            value={item.toLowerCase()}
                          >
                            {item}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  className="mb-1"
                  name={[_id, 'weight', 'unit']}
                  hidden
                  noStyle
                >
                  <Input />
                </Form.Item>
                <Form.Item shouldUpdate>
                  {({ getFieldValue }) => (
                    <Form.Item
                      className="mb-1"
                      name={[_id, 'weight', 'value']}
                      validateStatus={
                        getFieldValue([_id, 'weight', 'value']) > 0
                          ? 'success'
                          : 'error'
                      }
                    >
                      <InputNumber
                        onFocus={() => setFocusedIndex(index)}
                        autoFocus={focusedIndex === index}
                        min={0}
                        precision={1}
                        style={{ width: '100%' }}
                        placeholder={t('bulkPackaging.Overview.wInKGs')}
                      />
                    </Form.Item>
                  )}
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item
                  className="mb-1"
                  name={[_id, 'isReportable']}
                  valuePropName="checked"
                >
                  <Switch checkedChildren="Yes" unCheckedChildren="No" />
                </Form.Item>
              </Col>
              <Col span={1}>
                <Popconfirm
                  title={t('packagingEditor.overview.form.popConfirm')}
                  onConfirm={() => removeItem(_id)}
                  placement="topLeft"
                  disabled={readOnly}
                >
                  <Button
                    type="text"
                    hidden={state.items.length === 1}
                    disabled={readOnly}
                  >
                    <BsTrash />
                  </Button>
                </Popconfirm>
              </Col>
            </Row>
          ))}
          <Button
            type="primary"
            onClick={addItem}
            className="mt-4"
            disabled={readOnly}
          >
            {t('packagingEditor.overview.form.addMore')}
          </Button>
        </Form>
      </section>
      <section className="mt-5">
        <h2 className="text-lg font-bold">
          {t('packagingEditor.overview.evidenceSection')}
          <span
            style={{
              backgroundColor:
                editedRecord.assessmentMethodId &&
                (editedRecord.evidenceCount > 0 ||
                  editedRecord.assessmentComment)
                  ? '#0b0'
                  : '#e00',
            }}
            className="ml-4 px-3 py-1 text-white rounded-2xl"
          >
            {editedRecord.assessmentMethodId &&
            (editedRecord.evidenceCount > 0 || editedRecord.assessmentComment)
              ? t('packagingEditor.hasEvidence')
              : t('packagingEditor.missingEvidence')}
          </span>
        </h2>
        <Row gutter={16}>
          <Col span={12}>
            <Form
              layout="vertical"
              form={form}
              name="packaging-form"
              onValuesChange={debounce(handleInfoChanges, 500)}
              requiredMark={false}
            >
              <Form.Item
                label={
                  <>
                    {t('packagingEditor.overview.assessmentMethodLabel')} &nbsp;
                    <Button
                      type="link"
                      href="https://zerowastecity.com/wp-content/uploads/2021/09/MPR-Portal-Assessment-method.pdf"
                      target="_blank"
                      style={{ padding: 0 }}
                      className="flex items-center align-btn-icon"
                    >
                      {t('packagingEditor.overview.assessmentMethod')}{' '}
                      <InfoCircleFilled style={{ color: '#92d050' }} />
                    </Button>
                  </>
                }
                name="assessmentMethodId"
              >
                <Select
                  placeholder={t(
                    'packagingEditor.overview.form.selectAssessmentPlaceholder',
                  )}
                  optionFilterProp="children"
                  showSearch
                  allowClear
                  onClear={() => {
                    form.setFieldsValue({
                      assessmentMethodId: undefined,
                    });
                  }}
                >
                  {methodologies?.map((item) => (
                    <Select.Option key={item._id} value={item._id}>
                      {item.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label={t('packagingEditor.overview.form.comment')}
                name="assessmentComment"
              >
                <Input.TextArea
                  placeholder={t(
                    'packagingEditor.overview.form.commentPlaceholder',
                  )}
                  allowClear
                />
              </Form.Item>
              <div className="mb-2">
                What is the original source of the information (aka. where do
                the evidence come from)?
              </div>
              <Form.Item name="originalSources" noStyle>
                <Checkbox.Group
                  options={[
                    { label: getAccountTitle(auth), value: 'owner' },
                    {
                      label: 'Supplier or Manufacturer',
                      value: 'supplier',
                    },
                    { label: 'ZWC', value: 'zwc' },
                  ]}
                />
              </Form.Item>
              {/* Following checkbox field is just an eye candy, duplicate of otherSource */}
              <Form.Item name="otherSource" valuePropName="checked" noStyle>
                <Checkbox>Third party</Checkbox>
              </Form.Item>
              <Form.Item name="otherSource">
                <Input
                  style={{
                    display: 'inline',
                    width: '200px',
                    marginTop: '1rem',
                  }}
                  placeholder="Enter third party's name"
                />
              </Form.Item>
            </Form>
          </Col>
          <Col span={12}>
            <PackagingEvidenceFiles
              handleSave={handleSave}
              readonly={readOnly}
              packaging={editedRecord}
            />
          </Col>
        </Row>
      </section>
    </>
  );
};

export default Overview;
