import { DownOutlined } from '@ant-design/icons';
import {
  Button,
  Dropdown,
  Menu,
  Popconfirm,
  Table,
  Tag,
  Tooltip,
  message,
} from 'antd';
import { ColumnProps } from 'antd/lib/table';
import {
  SortOrder,
  SorterResult,
  TableRowSelection,
} from 'antd/lib/table/interface';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsFillTrashFill, BsPencil } from 'react-icons/bs';
import { HiDuplicate } from 'react-icons/hi';
import { MdArchive, MdUnarchive } from 'react-icons/md';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { PackagingsService } from '../../api/services';
import exportRecords from '../../components/exportRecords';
import { useActiveReportingPeriod } from '../../hooks/useActiveReportingPeriod';
import { useAuth } from '../../hooks/useAuth';
import { Packaging, Paginated } from '../../types';
import {
  handleSorting,
  isMasterAccount,
  isObserverUser,
  setUpSorting,
  switchedMasterAccount,
} from '../../utils';
import DownloadExports from '../Product/DownloadExports';
import { verifyComposition } from './edit-packaging/PackagingEditor/Components/PackagingEditor';
import { getFullName } from './getFullName';
import PackagingFilters, { PackagingListFilters } from './PackagingFilters';
import ImportPackagings from './PackagingImport/ImportPackagings';

const DEFAULT_LIMIT = +(localStorage.getItem('packagings-pageSize') || 50);
const INITIAL_STATE: Paginated<Packaging> = {
  data: [],
  limit: DEFAULT_LIMIT,
  skip: 0,
  total: 0,
};
const defaultSorting = { title: 1 };

const hostCompanyId = localStorage.getItem('view-as') || '';

const getFiltersQuery = (filters: PackagingListFilters) => {
  const { searchTerm, statuses, unAudited } = filters;
  const searchString = (searchTerm || '').trim();

  let query = {};
  if (statuses?.length) {
    query = Object.assign({}, query, { statuses });
  }
  if (unAudited) {
    query = Object.assign({}, query, { lastEditor: 'company' });
  }
  if (searchString) {
    query = Object.assign({}, query, {
      $multi_match: { $query: searchString },
    });
  }

  return query;
};

const PackagingContainer = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const history = useHistory();
  const [packagings, setPackagings] = useState(INITIAL_STATE);
  const [state, setState] = useState({
    isLoading: false,
    isEditing: false,
    editedRecord: {} as Packaging,
    filters: {} as PackagingListFilters,
    sorts: {},
    readOnly: false,
    selectedRowKeys: [] as React.Key[],
    selectAll: false,
    isExporting: false,
    isImporting: false,
    ripple: false,
    linksSort: null as SortOrder,
  });

  //path packagings?archived=true
  const search = useLocation().search;
  const archived = new URLSearchParams(search).get('archived') === 'true';

  const { reportingPeriod, isViewOnlyMode } = useActiveReportingPeriod();

  const { data = [], limit = DEFAULT_LIMIT, skip = 0, total = 0 } = packagings;

  const removePackaging = (packagingId: string) => {
    PackagingsService.remove(packagingId).catch((error: Error) => {
      message.error(t('packagingPage.container.messages.remove'));
      console.log('Error in removing packaging: ', error);
    });
  };
  const updatePackaging = (
    packagingId: string,
    packaging: Partial<Packaging>,
  ) => {
    message.loading({
      content: t('packagingPage.container.messages.saving'),
      key: 'packaging',
      duration: 0,
    });
    PackagingsService.patch(packagingId, packaging).then(
      (patchedPackaging: Packaging) => {
        setPackagings((old) => ({
          ...old,
          data: old.data.map((oldPackaging) =>
            oldPackaging?._id === patchedPackaging?._id
              ? patchedPackaging
              : oldPackaging,
          ),
        }));
        message.success({
          content: t('packagingPage.container.messages.saved'),
          key: 'packaging',
        });
      },
      (error: Error) => {
        console.log('Error in creating packaging: ', error);
        message.error({
          content: t('packagingPage.container.messages.updating'),
          key: 'packaging',
        });
      },
    );
  };
  const duplicatePackaging = (packagingId: string) => {
    PackagingsService.create({
      action: 'duplicatePackaging',
      packagingId,
      reportingPeriodId: reportingPeriod?._id,
    }).then(
      (packaging: Packaging) => {
        history.push(`edit-packaging/${packaging._id}`);
      },
      (e: Error) => {
        console.log('Error in duplicating packaging: ', e);
        message.error('Could not create duplicate');
      },
    );
  };
  const rowSelection: TableRowSelection<Packaging> = {
    preserveSelectedRowKeys: true,
    selectedRowKeys: state.selectedRowKeys,
    onChange: (selectedRowKeys) =>
      setState((old) => ({ ...old, selectedRowKeys })),
  };

  const actionMenu = (
    <Menu>
      {!isMasterAccount(auth) && !isObserverUser(auth) && !isViewOnlyMode ? (
        <Menu.Item
          onClick={() => setState((old) => ({ ...old, isImporting: true }))}
          key="1"
        >
          {t('Import packaging')}
        </Menu.Item>
      ) : null}
      {state.selectedRowKeys.length > 0 || state.selectAll ? (
        <Menu.Item
          onClick={() =>
            exportRecords({
              exportType: 'selectedRecords',
              serviceName: 'packagings',
              filters: JSON.stringify({
                _id: { $in: state.selectedRowKeys },
                reportingPeriodId: reportingPeriod?._id,
                isBulkPackaging: { $ne: true },
              }),
            })
          }
          key="2"
        >
          {t('Download Selected packaging')}
        </Menu.Item>
      ) : null}
      <Menu.Item
        key="3"
        onClick={() =>
          exportRecords({
            exportType: 'allRecords',
            serviceName: 'packagings',
            filters: JSON.stringify({
              ...getFiltersQuery(state.filters),
              reportingPeriodId: reportingPeriod?._id,
              isBulkPackaging: { $ne: true },
            }),
          })
        }
      >
        {t('Download all packagings')}
      </Menu.Item>
      <Menu.Item
        key="4"
        onClick={() => setState((old) => ({ ...old, isExporting: true }))}
      >
        {t('List of downloads')}
      </Menu.Item>
      <Menu.Item
        key="5"
        onClick={() => {
          history.push(`/packagings?archived=${!archived}`);
        }}
      >
        {t(`Show ${archived ? 'un-archived' : 'archived'} packaging`)}
      </Menu.Item>
    </Menu>
  );
  const applySelectAll = () => {
    setState((old) => ({
      ...old,
      selectAll: !old.selectAll,
      selectedRowKeys: !old.selectAll ? data.map((items) => items._id) : [],
    }));
  };

  let columns: ColumnProps<Packaging>[] = [
    {
      title: t('packagingPage.container.table.name'),
      dataIndex: 'title',
      render: (text, record) => (
        <div className="truncate" style={{ maxWidth: '400px' }} title={text}>
          {text}{' '}
          {(record.unreadMentions || []).includes(auth?.user?._id || '') && (
            <Tag color="#87d068">@</Tag>
          )}
        </div>
      ),
      ...setUpSorting(state.sorts, 'title', 1),
    },
    {
      width: '17%',
      title: t('packagingPage.container.table.status'),
      dataIndex: 'statuses',
      className: 'capitalize',
      ...setUpSorting(state.sorts, 'statuses', 3),
      render: (text) =>
        text.map((status: string) => <Tag key={status}>{status}</Tag>),
      onCell: (record) => ({
        style: {
          backgroundColor: record?.statuses?.includes('redFlag')
            ? '#F4B183'
            : 'white',
        },
      }),
    },
    {
      title: (
        <p className="text-center">
          {t('packagingPage.container.table.action')}
        </p>
      ),
      // width: '10%',
      dataIndex: 'actions',
      render: (text, record) => (
        <div style={{ width: '145px' }}>
          <Tooltip title="Edit package">
            <Button type="text">
              <Link
                to={`/edit-packaging/${record._id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <BsPencil />
              </Link>
            </Button>
          </Tooltip>
          {archived ? (
            <Popconfirm
              title={t('packagingPage.container.popMsg')}
              okText={t('packagingPage.ok')}
              placement="topLeft"
              onConfirm={() => {
                // console.log('Removing: ', record);
                removePackaging(record._id);
              }}
            >
              <Tooltip title="Delete Package">
                <Button type="text">
                  <BsFillTrashFill />
                </Button>
              </Tooltip>
            </Popconfirm>
          ) : (
            <Popconfirm
              title={t('Do you really archive package')}
              okText={t('packagingPage.ok')}
              placement="topLeft"
              onConfirm={() => {
                // console.log('archiving record: ', record);
                updatePackaging(record._id, { isArchived: true });
              }}
            >
              <Tooltip title="Archive package">
                <Button type="text">
                  <MdArchive />
                </Button>
              </Tooltip>
            </Popconfirm>
          )}

          <Popconfirm
            title="Do you really want to duplicate this packaging?"
            onConfirm={() => duplicatePackaging(record._id)}
          >
            <Tooltip title="Duplicate package">
              <Button type="text">
                <HiDuplicate className="text-lg" />
              </Button>
            </Tooltip>
          </Popconfirm>
        </div>
      ),
    },
    {
      title: t('packagingPage.container.table.lastChange'),
      width: '10%',
      dataIndex: 'updatedAt',
      render: (text) => (
        <div className="w-20">
          {text ? moment(text).format('DD MMM YYYY') : ''}
        </div>
      ),
      ...setUpSorting(state.sorts, 'updatedAt', 4),
    },
    {
      title: t('packagingPage.container.table.hasEvidence'),
      width: '10%',
      dataIndex: 'evidenceCount',
      ...setUpSorting(state.sorts, 'evidenceCount', 5),
      render: (text, record) => (
        <div className="w-14">
          {record.assessmentMethodId && (text > 0 || !!record.assessmentComment)
            ? 'Yes'
            : 'No'}
        </div>
      ),
      onCell: (record) => ({
        style: {
          backgroundColor:
            record.assessmentMethodId &&
            (record.evidenceCount > 0 || !!record.assessmentComment)
              ? '#C5E0B4'
              : 'white',
        },
      }),
    },
    {
      title: t('packagingPage.container.table.completed'),
      width: '5%',
      render: (text, record) => (
        <div className="w-10">
          {verifyComposition(record.separableItems) ? 'Yes' : 'No'}
        </div>
      ),
      onCell: (record) => ({
        style: {
          backgroundColor: verifyComposition(record.separableItems)
            ? '#C5E0B4'
            : 'white',
        },
      }),
    },
    {
      title: t('packagingPage.container.table.linksCount'),
      width: '5%',
      dataIndex: 'linkedProductIds',
      sorter: true,
      sortOrder: state.linksSort,
      render: (text, record) => {
        let count = Array.isArray(text) ? text.length : 0;

        if (count < 1) {
          return <div className="text-center w-16">{count || 0}</div>;
        }

        return (
          <div className="text-center w-16">
            <Link
              target="_blank"
              to={`/products?filters=${encodeURIComponent(
                record?.title || '',
              )}`}
            >
              {count || 0}
            </Link>
          </div>
        );
      },
    },
    {
      title: t('packagingPage.container.table.lastEditorName'),
      width: '20%',
      dataIndex: 'lastEditorName',
      ...setUpSorting(state.sorts, 'lastEditorName', 3),
      render: (_text, record) => (
        <div className="w-20">{getFullName(record.user)}</div>
      ),
    },
    {
      // width: 150,
      width: '20%',
      title: t('packagingPage.container.table.description'),
      dataIndex: 'description',
      render: (text) => (
        <div className="truncate" style={{ maxWidth: '300px' }} title={text}>
          {text}
        </div>
      ),
      ...setUpSorting(state.sorts, 'description', 2),
    },
  ];
  if (isObserverUser(auth) || isViewOnlyMode) {
    columns = columns.filter((item) => item.dataIndex !== 'actions');
  }
  useEffect(() => {
    setState((old) => ({ ...old, isLoading: true }));
    setState((old) => ({
      ...old,
      selectAll: false,
      selectedRowKeys: [],
    }));
    const filtersQuery = getFiltersQuery(state.filters);
    const $sort =
      Object.keys(state.sorts)?.length > 0 ? state.sorts : defaultSorting;
    PackagingsService.find({
      query: {
        $sort,
        $limit: limit,
        $skip: skip,
        isBulkPackaging: { $ne: true },
        isArchived: archived || { $ne: true },
        ...filtersQuery,
        reportingPeriodId: reportingPeriod?._id,
      },
    })
      .then(
        (res: Paginated<Packaging>) => setPackagings(res),
        (e: Error) => {
          console.log('Error in fetching packagings: ', { error: e });
          message.error(t('packagingPage.container.messages.fetching'));
        },
      )
      .finally(() => setState((old) => ({ ...old, isLoading: false })));
  }, [limit, skip, state.filters, state.sorts, state.ripple, archived]);
  useEffect(() => {
    const handleCreate = (res: Packaging) => {
      const companyId = switchedMasterAccount(auth)
        ? hostCompanyId
        : auth?.user?.companyId || '';
      //@ts-ignore
      if (!res?.companyId?.includes(companyId) || res.isBulkPackaging) {
        return;
      }
      setPackagings((old) => ({
        ...old,
        data: [res, ...old.data],
        total: old.total + 1,
      }));
    };
    const handlePatch = (res: Packaging) =>
      setPackagings((old) => ({
        ...old,
        data: old.data.map((item) => (item._id === res._id ? res : item)),
      }));

    const handleRemove = (res: Packaging) =>
      setPackagings((old) => ({
        ...old,
        data: old.data.filter((item) => item._id !== res._id),
      }));

    PackagingsService.on('created', handleCreate);
    PackagingsService.on('patched', handlePatch);
    PackagingsService.on('removed', handleRemove);
    return () => {
      PackagingsService.off('created', handleCreate);
      PackagingsService.off('patched', handlePatch);
      PackagingsService.off('removed', handleRemove);
    };
  }, []);

  return (
    <div className="p-4 bg-white">
      <header className="mb-4">
        <div className="flex items-center">
          <div className="font-sans text-2xl font-bold md:text-3xl mr-auto">
            {t('packagingPage.container.title')}
          </div>
          {state.selectedRowKeys.length > 0 || state.selectAll ? (
            <>
              {!isObserverUser(auth) && !isViewOnlyMode && archived && (
                <Button
                  type="primary"
                  className="mr-2"
                  onClick={() => {
                    state.selectedRowKeys.forEach((el: any) => {
                      updatePackaging(el, { isArchived: false });
                    });
                  }}
                >
                  <MdUnarchive />
                </Button>
              )}
              <Button
                onClick={() =>
                  setState((old) => ({
                    ...old,
                    selectAll: false,
                    selectedRowKeys: [],
                  }))
                }
                className="mr-2"
                danger
                ghost
              >
                Clear{' '}
                {state.selectAll
                  ? packagings.total
                  : state.selectedRowKeys.length}{' '}
                selections
              </Button>
            </>
          ) : null}
          {!isMasterAccount(auth) &&
          !isObserverUser(auth) &&
          !isViewOnlyMode ? (
            <Button
              type="primary"
              className="font-bold text-white uppercase ml-2"
            >
              <Link to="/edit-packaging/new">
                {t('packagingPage.container.btnText')}
              </Link>
            </Button>
          ) : null}
          <Dropdown overlay={actionMenu} arrow>
            <Button className="ml-2">
              Actions <DownOutlined />
            </Button>
          </Dropdown>
        </div>
        <PackagingFilters
          filters={state.filters}
          setFilters={(filters) => setState((old) => ({ ...old, filters }))}
          selectAll={state.selectAll}
          selectionCount={packagings.total}
          onSelectAll={applySelectAll}
        />
      </header>
      <main>
        <Table
          rowKey="_id"
          columns={columns}
          dataSource={data.filter((i) =>
            archived ? i.isArchived : !i.isArchived,
          )}
          rowSelection={rowSelection}
          pagination={{
            total,
            pageSize: limit,
            current: skip / limit + 1,
            showSizeChanger: true,
            pageSizeOptions: ['50', '100', '250', '500'],
            onShowSizeChange: (page, size = limit) => {
              localStorage.setItem('packagings-pageSize', size.toString());
              setPackagings((old) => ({
                ...old,
                skip: (page - 1) * size,
                limit: size,
              }));
            },
            onChange: (page, size = limit) =>
              setPackagings((old) => ({
                ...old,
                skip: (page - 1) * size,
                limit: size,
              })),
          }}
          onChange={(a, b, sort = {}) => {
            if (
              (sort as SorterResult<Packaging>).field === 'linkedProductIds'
            ) {
              let newOrder: SortOrder = null;
              let sortFunc = (a: any, b: any): number =>
                a.title < b.title ? -1 : 1;

              if (state.linksSort === null) {
                newOrder = 'descend';
                sortFunc = (a, b) =>
                  ((b.linkedProductIds || []).length || 0) -
                  ((a.linkedProductIds || []).length || 0);
              } else if (state.linksSort === 'descend') {
                newOrder = 'ascend';
                sortFunc = (a: any, b: any) =>
                  ((a.linkedProductIds || []).length || 0) -
                  ((b.linkedProductIds || []).length || 0);
              } else {
                newOrder = null;
              }
              setState((old) => ({ ...old, linksSort: newOrder }));
              setPackagings((old) => ({
                ...old,
                data: old.data.sort(sortFunc),
              }));
            } else {
              handleSorting(
                sort as SorterResult<Packaging>[],
                state.sorts,
                (sorts) => setState((old) => ({ ...old, sorts })),
              );
            }
          }}
          loading={state.isLoading}
          scroll={{
            x: true,
            y: 'calc(100vh - 386px)',
          }}
        />
      </main>

      <DownloadExports
        serviceName="packagings"
        visible={state.isExporting}
        handleClose={() =>
          setState((old) => ({
            ...old,
            isExporting: false,
          }))
        }
      />
      <ImportPackagings
        visible={state.isImporting}
        handleClose={(reload) => {
          setState((old) => ({ ...old, isImporting: false }));
          if (reload) {
            setState((old) => ({ ...old, ripple: !old.ripple }));
          }
        }}
      />
    </div>
  );
};

export default PackagingContainer;
