import CommentsEditor from './CommentsEditor';
import CommentsList from './CommentsList';

export default function PackagingComments({
  packagingId,
}: {
  packagingId: string;
}) {
  return (
    <div className="p-4 border-2" style={{ height: 'inherit' }}>
      <CommentsEditor packagingId={packagingId} />
      <div className="mt-5">
        <CommentsList packagingId={packagingId} />
      </div>
    </div>
  );
}
