import { Button, Form, Mentions, message } from 'antd';
import { OptionProps } from 'antd/lib/mentions';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { useAuth } from '../../../../../../hooks/useAuth';
import { User } from '../../../../../../types';
import { getFullName } from '../../../../getFullName';
import { createPackagingComment } from './api';
import { useCompanyUsers } from './useCompanyUsers';
import { useZWCUsers } from './useZWCUsers';

const { Option, getMentions } = Mentions;

const CommentsEditor = ({ packagingId }: { packagingId: string }) => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const { mutate: addNewComment, isLoading } = useMutation(
    'createComment',
    createPackagingComment,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('packaging/comments');
        form.resetFields();
      },
      onError: (e) => {
        console.log('Error in adding the comment: ', e);
        message.error('Error in adding the comment');
      },
    },
  );

  const handleSubmit = () => {
    form.validateFields().then((comment) => {
      addNewComment({ ...comment, packagingId });
    });
  };

  const { user } = useAuth();

  const setUniqueUsers = (users: Array<User>) => {
    return setAllUsers((old) =>
      Object.values(
        [...users, ...old]
          .filter((i) => i._id !== user?._id)
          .reduce((a: any, c: User) => ({ ...a, [c._id]: c }), {}),
      ),
    );
  };

  useZWCUsers({
    onSuccess: (res: Array<User>) => setUniqueUsers(res),
  });

  useCompanyUsers({
    onSuccess: (res: Array<User>) => setUniqueUsers(res),
  });

  return (
    <div>
      <Form
        name="packaging-comments-form"
        onFinish={handleSubmit}
        form={form}
        autoComplete="off"
      >
        <Form.Item
          name="text"
          rules={[
            {
              required: true,
              message: 'Please input your comment!',
              whitespace: true,
            },
          ]}
          style={{ marginBottom: '0.5rem' }}
        >
          <Mentions
            rows={3}
            placeholder="Post a comment"
            onKeyPress={(e) => {
              if (e.code === 'Enter' && e.ctrlKey && !isLoading) {
                handleSubmit();
              }
            }}
            // @ts-ignore
            filterOption={(str: string, { value }: OptionProps) => {
              const result = (value || '')
                .trim()
                .toLowerCase()
                .startsWith((str || '').toLowerCase());
              // console.log('str', str, 'value', value, 'result', result);
              return result;
            }}
          >
            {allUsers?.map((el) => {
              return (
                <Option
                  value={`${getFullName(el)} (${el?._id})`}
                  key={`${el?.firstName} (${el?._id})`}
                >
                  {`${getFullName(el)} (${el?._id})`}
                </Option>
              );
            })}
          </Mentions>
        </Form.Item>

        <div className="flex justify-end">
          <Button
            disabled={!packagingId || isLoading}
            type="primary"
            htmlType="submit"
          >
            Post Comment
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default CommentsEditor;
