import { MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Popconfirm, Table, message } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { UsersService } from '../../api/services';
import { useAuth } from '../../hooks/useAuth';
import { Paginated, User } from '../../types';
import { isAdmin, isObserverUser } from '../../utils';
import { getUserRoleLabel } from './getUserRoleLabel';
import PasswordUpdate from './PasswordUpdate';
import UserEdit from './UserEdit';

const defaultLimit = +(localStorage.getItem('users-pageSize') || 50);
const UsersContainer = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const auth = useAuth();
  const search = useLocation().search;
  const archived = new URLSearchParams(search).get('archived') === 'true';
  const [userEdit, setUserEdit] = useState({
    isEditing: false,
    editedRecord: {} as User,
  });
  const [users, setUsers] = useState({
    data: [],
    limit: defaultLimit,
    skip: 0,
    total: 0,
  } as Paginated<User>);
  const [passwordReset, setPasswordReset] = useState({
    isEditing: false,
    userId: '',
  });
  const [state, setState] = useState({ loading: false });

  const { data = [], limit = defaultLimit, skip = 0, total = 0 } = users;

  const handleRemove = (userId: string) => {
    setState((old) => ({ ...old, loading: true }));
    UsersService.remove(userId)
      .catch((e: Error) => {
        console.log('Error in deleting user: ', e);
        message.error(t('usersPage.list.messages.delete'));
      })
      .finally(() => setState((old) => ({ ...old, loading: false })));
  };
  type userArchive = {
    isArchived: boolean;
  };
  const updateIsArchived = (id: string, data: userArchive) => {
    // message.loading({
    //   content: t('usersPage.container.messages.saving'),
    //   key: 'users',
    //   duration: 0,
    // });
    setState({ loading: true });
    console.log(id, data);
    UsersService.patch(id, data).then(
      () => {
        message.success({
          content: t('user updated'),
          key: 'users',
        });
      },
      (error: Error) => {
        console.log('Error in archiving user: ', error);
        message.error({
          content: t('users is archivinng'),
          key: 'users',
        });
      },
    );
    setState({ loading: false });
  };
  const toggleActivation = (userId: string, isActive: boolean) => {
    console.log(userId);
    UsersService.patch(userId, { isActive }).catch((error: Error) => {
      message.error(
        isActive
          ? t('usersPage.list.messages.couldNotActive')
          : t('usersPage.list.messages.couldNotDeactivate'),
      );
      console.log('Error in updating user: ', error.message);
    });
  };
  const handleClose = () =>
    setUserEdit({ isEditing: false, editedRecord: {} as User });
  let columns: ColumnProps<User>[] = [
    {
      title: t('usersPage.list.table.reference#'),
      dataIndex: '_id',
    },
    {
      title: t('usersPage.list.table.firstName'),
      dataIndex: 'firstName',
      render: (text, record) =>
        record._id === auth?.user?._id ? `${text} (you)` : text,
    },
    { title: t('usersPage.list.table.lastName'), dataIndex: 'lastName' },
    { title: t('usersPage.list.table.email'), dataIndex: 'email' },
    { title: t('usersPage.list.table.phoneNumber'), dataIndex: 'phone' },
    { title: t('usersPage.list.table.jobTitle'), dataIndex: 'title' },
    {
      title: t('usersPage.list.table.role'),
      dataIndex: 'role',
      render: (text) => getUserRoleLabel(text),
    },
    {
      title: t('usersPage.list.table.status'),
      dataIndex: 'isActive',
      render: (text) => (text ? 'Active' : 'Disabled'),
    },
    {
      title: t('usersPage.list.table.action'),
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <Dropdown
            arrow
            overlay={
              <Menu>
                <Menu.Item
                  key="1"
                  onClick={() =>
                    setUserEdit({ isEditing: true, editedRecord: record })
                  }
                >
                  {t('usersPage.list.actions.edit')}
                </Menu.Item>
                <Menu.Item
                  key="2"
                  onClick={() =>
                    setPasswordReset({ isEditing: true, userId: record._id })
                  }
                  disabled={!isAdmin(auth) && record._id !== auth.user?._id}
                >
                  {t('usersPage.list.actions.reset')}
                </Menu.Item>
                <Menu.Item
                  key="3"
                  disabled={record._id === auth?.user?._id || !isAdmin(auth)}
                >
                  <Popconfirm
                    title={
                      record.isActive
                        ? t('usersPage.list.messages.popConfirmDeactivateText')
                        : t('usersPage.list.messages.popConfirmActivateText')
                    }
                    okText={t('usersPage.list.okText')}
                    onConfirm={() =>
                      toggleActivation(record._id, !record.isActive)
                    }
                    placement="bottomLeft"
                    disabled={record._id === auth?.user?._id || !isAdmin(auth)}
                  >
                    {record.isActive
                      ? t('usersPage.list.messages.deactivate')
                      : t('usersPage.list.messages.activate')}
                  </Popconfirm>
                </Menu.Item>
                <Menu.Item
                  key="4"
                  disabled={record._id === auth?.user?._id || !isAdmin(auth)}
                >
                  <Popconfirm
                    title={t('usersPage.list.messages.popDeleteConfirmText')}
                    okText={t('usersPage.list.okText')}
                    onConfirm={() => handleRemove(record._id)}
                    disabled={record._id === auth?.user?._id || !isAdmin(auth)}
                  >
                    {t('usersPage.list.actions.delete')}
                  </Popconfirm>
                </Menu.Item>
                {archived ? (
                  <Menu.Item
                    key="5"
                    onClick={() =>
                      updateIsArchived(record?._id, { isArchived: false })
                    }
                  >
                    Unarchived
                  </Menu.Item>
                ) : (
                  <Menu.Item
                    key="6"
                    onClick={() =>
                      updateIsArchived(record._id, { isArchived: true })
                    }
                  >
                    Archive
                  </Menu.Item>
                )}
              </Menu>
            }
          >
            <MoreOutlined className="px-2 cursor-pointer" />
          </Dropdown>
        );
      },
    },
  ];

  if (isObserverUser(auth)) {
    columns = columns.filter((item) => item.dataIndex !== 'actions');
  }

  useEffect(() => {
    setState((old) => ({ ...old, loading: true }));
    UsersService.find({
      query: {
        $sort: { createdAt: -1 },
        $limit: limit,
        $skip: skip,
        isArchived: archived ? true : { $ne: true },
      },
    })
      .then(
        (res: Paginated<User>) => setUsers(res),
        (e: Error) => {
          console.log('Error in fetching users: ', { error: e });
          message.error('Error in fetching users');
        },
      )
      .finally(() => setState((old) => ({ ...old, loading: false })));
  }, [limit, skip, archived]);
  useEffect(() => {
    const handleCreate = (res: User) =>
      setUsers((old) => ({
        ...old,
        data: [res, ...old.data],
        total: old.total + 1,
      }));
    const handlePatch = (res: User) =>
      setUsers((old) => ({
        ...old,
        data: old.data.map((item) => (item._id === res._id ? res : item)),
      }));

    const handleRemove = (res: User) =>
      setUsers((old) => ({
        ...old,
        data: old.data.filter((item) => item._id !== res._id),
      }));

    UsersService.on('created', handleCreate);
    UsersService.on('updated', handlePatch);
    UsersService.on('patched', handlePatch);
    UsersService.on('removed', handleRemove);
    return () => {
      UsersService.off('created', handleCreate);
      UsersService.off('updated', handlePatch);
      UsersService.off('patched', handlePatch);
      UsersService.off('removed', handleRemove);
    };
  }, []);

  return (
    <div className="p-4 bg-white">
      <header className="flex items-center justify-between mb-4">
        <h1 className="font-sans text-2xl font-bold md:text-3xl">
          {t('usersPage.list.users')}
        </h1>

        {isObserverUser(auth) ? null : (
          <div>
            <Button
              className="mr-2"
              onClick={() => {
                history.push(`/users?archived=${!archived}`);
              }}
            >
              Show {!archived ? 'archived' : 'unarchived'} users
            </Button>
            <Button
              type="primary"
              className="font-bold text-white"
              onClick={() =>
                setUserEdit({ isEditing: true, editedRecord: {} as User })
              }
            >
              {t('usersPage.list.btnTxt')}
            </Button>
          </div>
        )}
      </header>
      <main>
        <Table
          rowKey="_id"
          columns={columns}
          dataSource={data.filter((i) =>
            archived ? i.isArchived : !i.isArchived,
          )}
          pagination={{
            total,
            pageSize: limit,
            current: skip / limit + 1,
            showSizeChanger: true,
            pageSizeOptions: ['50', '100', '250', '500'],
            onShowSizeChange: (page, size = limit) => {
              localStorage.setItem('users-pageSize', size.toString());
              setUsers((old) => ({
                ...old,
                skip: (page - 1) * size,
                limit: size,
              }));
            },
            onChange: (page, size = limit) =>
              setUsers((old) => ({
                ...old,
                skip: (page - 1) * size,
                limit: size,
              })),
          }}
          loading={state.loading}
          scroll={{ x: true }}
        />
      </main>

      <UserEdit {...userEdit} handleClose={handleClose} />
      <PasswordUpdate
        {...passwordReset}
        handleClose={() => setPasswordReset({ isEditing: false, userId: '' })}
      />
    </div>
  );
};

export default UsersContainer;
