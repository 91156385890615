import React, {
  Dispatch,
  PropsWithChildren,
  createContext,
  useEffect,
  useReducer,
} from 'react';

import { ReportingPeriod } from '../types';
import useDebounce from './useDebounce';

export const sessionKey = 'session';

export interface SessionState {
  isLoading: boolean;
  reportingPeriod?: ReportingPeriod;
  isViewOnlyMode?: boolean;
  hasActiveReportingPeriod: boolean;
}

const SessionStateContext = createContext<SessionState | undefined>(undefined);
const SessionDispatchContext = createContext<Dispatch<Action> | undefined>(
  undefined,
);

function getDefaultState(): SessionState {
  let result = {
    isLoading: false,
    reportingPeriod: undefined,
    isViewOnlyMode: true,
    hasActiveReportingPeriod: true,
  };

  return result;
}

function getInitialState() {
  let result;
  let savedState = sessionStorage.getItem(sessionKey);
  if (savedState) {
    result = JSON.parse(savedState);
    result.isLoading = false;
  } else {
    result = getDefaultState();
  }
  return result;
}

type Action =
  | { type: 'reset session' }
  | { type: 'set_session'; payload: Partial<SessionState> };

function sessionStateReducer(
  state: SessionState,
  action: Action,
): SessionState {
  console.log('session update, ', state, action);
  //TODO: extract derived state from reducer
  switch (action.type) {
    case 'set_session': {
      return {
        ...state,
        ...action.payload,
        hasActiveReportingPeriod: !!action.payload.reportingPeriod,
      };
    }

    case 'reset session': {
      return {
        hasActiveReportingPeriod: false,
        reportingPeriod: undefined,
        isLoading: false,
        isViewOnlyMode: true,
      };
    }

    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
}

export function ActiveReportingPeriodProvider({
  children,
}: PropsWithChildren<{}>) {
  const [state, dispatch] = useReducer(
    sessionStateReducer,
    {},
    getInitialState,
  );

  const debouncedState = useDebounce(state, 300);

  const isViewOnlyMode = state?.reportingPeriod?.isArchived;

  useEffect(() => {
    sessionStorage.setItem(sessionKey, JSON.stringify(debouncedState));
  }, [debouncedState]);

  return (
    <SessionStateContext.Provider value={{ ...state, isViewOnlyMode }}>
      <SessionDispatchContext.Provider value={dispatch}>
        {children}
      </SessionDispatchContext.Provider>
    </SessionStateContext.Provider>
  );
}

/**
 * This hook returns the reporting period currently activated by user,
 * this may not be the  latest) reporting period, if you
 * want to get the most recent/current reporting period,
 * then use useLatestReportingPeriod hook instead
 * e.g. if user has following reporting periods
 *
 * 2022, 2021, 2020 (active), 2019
 *
 * it will return 2020 reporting period's data because that is activated
 * by user
 */
export function useActiveReportingPeriod() {
  const context = React.useContext(SessionStateContext);
  if (context === undefined) {
    throw new Error(
      'useActiveReportingPeriod must be used within a SessionProvider',
    );
  }
  return context;
}

export function useActiveReportingPeriodDispatch() {
  const context = React.useContext(SessionDispatchContext);
  if (context === undefined) {
    throw new Error('useSessionDispatch must be used within a SessionProvider');
  }
  return context;
}
