import { useQuery } from 'react-query';

import { User } from '../../../../../../types';
import { findUsers } from './findUsers';

export function useCompanyUsers(options = {}) {
  const { data, ...res } = useQuery(['company-users'], findUsers, {
    staleTime: 5 * 60 * 1000,
    ...options,
  });

  const usersById = (data || []).reduce(
    (a: { [index: string]: User }, c: User) => ({ ...a, [c._id]: c }),
    {} as { [index: string]: User },
  );

  return { ...res, data, usersById };
}
