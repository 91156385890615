import { Comment, Tooltip } from 'antd';
import moment from 'moment';

import { PackagingComment } from '../../../../../../types';

const CommentItem = ({ data }: { data: PackagingComment }) => {
  const { text, createdAt, userId } = data;

  return (
    <Comment
      author={userId}
      content={<p className="-my-1">{text}</p>}
      className="remove-padding mt-2"
      datetime={
        <Tooltip title={moment(createdAt).fromNow()}>
          <span>{moment(createdAt).format('DD MMM YYYY HH:mm')}</span>
        </Tooltip>
      }
    />
  );
};

export default CommentItem;
