import { Badge, Button, Form, Tabs, Tooltip, message } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaSpinner } from 'react-icons/fa';

import { PackagingsService } from '../../../api/services';
import { useActiveReportingPeriod } from '../../../hooks/useActiveReportingPeriod';
import { BulkPackaging, SeparableItem } from '../../../types';
import AuditTrailTable from './Components/AuditTrail';
import Overview from './Components/Overview';

const { TabPane } = Tabs;

interface BulkPackagingEditorContainerProps {
  editedRecord: BulkPackaging;
  readOnly: boolean;
  handleClose: () => void;
  handleSave: (packaging: Partial<BulkPackaging>) => void;
}
declare type tabName = 'noChange' | 'overview' | 'items' | 'evidence';

const BulkPackagingEditorContainer = ({
  editedRecord,
  readOnly,
  handleClose,
  handleSave,
}: BulkPackagingEditorContainerProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<BulkPackaging>();
  const [separableItemsForm] = Form.useForm<SeparableItem>();
  const [activeTab, setActiveTab] = useState('overview');
  const { reportingPeriod } = useActiveReportingPeriod();

  const handleSubmit = (
    formChanges: Partial<BulkPackaging>,
    tab = 'noChange' as tabName,
    justValidateForms = false,
  ) => {
    // console.log('Submitting...');
    if (readOnly) {
      if (tab !== 'noChange') {
        setActiveTab(tab);
      }
      return;
    }
    form.validateFields().then(
      (info) =>
        separableItemsForm.validateFields().then(
          (items) => {
            if (!justValidateForms) {
              const { _id: packagingId } = editedRecord;
              const { _id: omit, ...allForm } = info;
              if (packagingId) {
                const savable = Object.entries(formChanges).reduce(
                  (a, [k, v]) => ({ ...a, [k]: v === undefined ? null : v }),
                  {},
                );
                PackagingsService.patch(packagingId, savable).then(
                  (res: BulkPackaging) => {
                    const { separableItems, ...rest } = res;
                    handleSave(rest);
                  },
                  (error: Error) => {
                    console.log('Error in creating packaging: ', error);
                    message.error({
                      content: t('packagingEditor.container.messages.updating'),
                      key: 'packaging',
                    });
                  },
                );
              } else {
                message.loading({
                  content: t('packagingEditor.container.messages.saving'),
                  key: 'packaging',
                  duration: 0,
                });
                PackagingsService.create({
                  ...allForm,
                  isBulkPackaging: true,
                  reportingPeriodId: reportingPeriod?._id,
                  separableItems: Object.values(items).map(
                    ({ _id, ...restOfItem }) => restOfItem,
                  ),
                }).then(
                  (res: BulkPackaging) => {
                    console.log('BulkPackaging created: ', res);
                    message.success({
                      content: t('packagingEditor.container.messages.saved'),
                      key: 'packaging',
                    });
                    handleSave(res);
                  },
                  (error: Error) => {
                    console.log('Error in creating packaging: ', error);
                    message.error({
                      content: t(
                        'packagingEditor.container.messages.savingPkg',
                      ),
                      key: 'packaging',
                    });
                  },
                );
              }
            } else {
              tab !== 'noChange' ? setActiveTab(tab) : null;
            }
          },
          () => console.log('Items form invalid'),
        ),
      (e) => console.log('Info form invalid: ', e),
    );
  };

  return (
    <div className="p-4 bg-white">
      <header className="flex items-center">
        <div className="mr-auto font-sans text-2xl font-bold md:text-3xl">
          {editedRecord.title || 'Bulk packaging editor'}{' '}
          {readOnly ? (
            <Tooltip
              title={t('packagingEditor.toolTipTxt')}
              placement="right"
              autoAdjustOverflow
            >
              <Badge
                count="Read only mode"
                className="ml-1"
                style={{ backgroundColor: 'lightgray' }}
                title=""
              />
            </Tooltip>
          ) : (
            ''
          )}
        </div>
        <Button
          type="primary"
          className="font-bold text-white uppercase"
          onClick={handleClose}
        >
          {t('packagingEditor.btnTxt')}
        </Button>
      </header>
      <main>
        <Tabs
          activeKey={activeTab}
          onChange={(tab) => handleSubmit({}, tab as tabName, true)}
        >
          <TabPane tab={t('packagingEditor.tabPane-1')} key="overview">
            <Overview
              editedRecord={editedRecord}
              readOnly={readOnly}
              form={form}
              itemsForm={separableItemsForm}
              tab={activeTab}
              handleSave={handleSave}
              handleInfoChanges={(values) =>
                !!editedRecord._id || !editedRecord._id
                  ? handleSubmit(values, 'noChange')
                  : null
              }
            />
          </TabPane>
          <TabPane tab={t('packagingEditor.tabPane-3')} key="evidence">
            <AuditTrailTable packagingId={editedRecord._id} />
          </TabPane>
        </Tabs>
      </main>
      {/* FIXME: run this only on mutations */}
      {false ? (
        <div
          className="fixed flex items-center justify-center text-center text-gray-600 rounded-full"
          style={{
            bottom: '50px',
            right: '50px',
          }}
        >
          <FaSpinner className="riz-spinner" size={20} />
          <span className="ml-1 text-sm font-semibold">
            {t('packagingEditor.saving')}
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default BulkPackagingEditorContainer;
