import { SearchOutlined } from '@ant-design/icons';
import {
  Button,
  Input,
  InputNumber,
  Popconfirm,
  Switch,
  Table,
  message,
} from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { ColumnProps } from 'antd/lib/table';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useTranslation } from 'react-i18next';

import { PackagingLinkService, PackagingsService } from '../../api/services';
import { useActiveReportingPeriod } from '../../hooks/useActiveReportingPeriod';
import { useAuth } from '../../hooks/useAuth';
import useDebounce from '../../hooks/useDebounce';
import { Packaging, PackagingLink, Paginated, Product } from '../../types';
import { switchedMasterAccount } from '../../utils';
import { SelectionFiltersQuery } from './ProductList';

interface LinkPackagingProps {
  visible: boolean;
  handleClose: (shouldReload: boolean) => void;
  products: Product[];
  selectAll: boolean;
  selectionFilters: SelectionFiltersQuery;
  createAndAssignNewPackaging: () => void;
}
const defaultLimit = +(localStorage.getItem('linkPackagings-pageSize') || 50);
const INITIAL_STATE = {
  data: [],
  limit: defaultLimit,
  skip: 0,
  total: 0,
};
const hostCompanyId = localStorage.getItem('view-as');

const LinkPackaging = ({
  visible,
  products = [],
  handleClose,
  selectAll,
  selectionFilters,
  createAndAssignNewPackaging,
}: LinkPackagingProps) => {
  const { t } = useTranslation();
  const [packagings, setPackagings] =
    useState<Paginated<Packaging>>(INITIAL_STATE);
  const [linkedPackagings, setLinkedPackagings] = useState<PackagingLink[]>([]);
  const [state, setState] = useState({
    isLoadingPackagings: false,
    isLoadingLinks: false,
    filterValues: {},
    ripple: false,
    itemsCountColor: 'bg-white',
    linksFetched: false,
    linkedIds: [] as string[],
    shouldReload: false,
  });
  const { reportingPeriod } = useActiveReportingPeriod();

  //TODO: this is a kludge, it cause a manual refresh at predetermined intervals which doesn't work reliablly. If user perform some action after given intervals then the modal will not be refreshed, ideally we'd have someway to send event from server to client that it needs to refresh it self. However that may also not be practical because there might be updates to many links at once. An ideal solution might be to assign jobId to each action and then only alert clients interested in that jobId. However then updates may not appear to some other client in realtime. We can also create a tick continously after certain random threshold but that may cause unnecessary requests to server

  let debouncedRipple1 = useDebounce(state.ripple, 4000);
  let debouncedRipple2 = useDebounce(state.ripple, 10000);

  const [filters, setFilters] = useState({});
  const auth = useAuth();
  const { data = [], limit = defaultLimit, skip = 0, total = 0 } = packagings;

  const linkPackaging = (packaging: PackagingLink) => {
    const { subitemsCount = 1, isReportable = true, packagingId } = packaging;
    message.loading({
      content: t('productPage.linkPackaging.messages.assign'),
      duration: 0,
      key: 'processing',
    });
    setState((old) => ({ ...old, shouldReload: true }));
    PackagingLinkService.patch(
      null,
      {
        action: 'updatePackaging',
        packagingId,
        subitemsCount,
        isReportable,
      },
      {
        query: {
          reportingPeriodId: reportingPeriod?._id,
          ...(selectAll
            ? selectionFilters
            : { _id: { $in: products.map((item) => item._id) } }),
        },
      },
    ).then(
      () => {
        setState((old) => ({ ...old, ripple: !old.ripple }));
        message.success({
          content: t('productPage.linkPackaging.messages.pkgLink'),
          key: 'processing',
        });
      },
      (e: Error) => {
        console.log(t('productPage.linkPackaging.messages.errAssign'), e);
        message.error({
          content: t('productPage.linkPackaging.messages.errAssign'),
          key: 'processing',
        });
      },
    );
  };
  const removePackagingLink = (packagingLink: PackagingLink) => {
    message.loading({
      content: t('productPage.linkPackaging.messages.unAssignPkg'),
      duration: 0,
      key: 'processing',
    });
    setState((old) => ({ ...old, shouldReload: true }));
    const { packagingId } = packagingLink;
    PackagingLinkService.remove(null, {
      query: {
        action: 'removePackaging',
        reportingPeriodId: reportingPeriod?._id,
        packagingId,
        ...(selectAll
          ? selectionFilters
          : { _id: { $in: products.map((item) => item._id) } }),
      },
    }).then(
      () => {
        setState((old) => ({ ...old, ripple: !old.ripple }));
        message.success({
          content: t('productPage.linkPackaging.messages.pkgUnlinked'),
          key: 'processing',
        });
      },
      (e: Error) => {
        console.log(t('productPage.linkPackaging.messages.errAssign'), e);
        message.error({
          content: t('productPage.linkPackaging.messages.errAssign'),
          key: 'processing',
        });
      },
    );
  };
  const tempUpdatePackaging = (packagingId: string, changes = {} as any) => {
    setPackagings((old) => ({
      ...old,
      data: old.data.map((item) =>
        item._id === packagingId ? { ...item, ...changes } : item,
      ),
    }));
  };
  const getColumnSearchProps = (dataIndex: string, fieldName: string) => ({
    filterDropdown: () => (
      <div className="p-1">
        <Input
          placeholder={`${t('productPage.searchText')} ${fieldName}`}
          //@ts-ignore
          value={state.filterValues[dataIndex]}
          onChange={(e) => {
            const value = e.target.value;
            setState((old) => ({
              ...old,
              filterValues: { ...old.filterValues, [dataIndex]: value },
            }));
          }}
          onBlur={() => setFilters(state.filterValues)}
          onPressEnter={() => setFilters(state.filterValues)}
          autoFocus
          allowClear
        />
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    render: (text: string) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        //@ts-ignore
        searchWords={[filters[dataIndex]]}
        autoEscape
        textToHighlight={text}
      />
    ),
  });
  const packagingColumns: ColumnProps<Packaging>[] = [
    {
      title: t('productPage.linkPackaging.packagingTable.name'),
      dataIndex: 'title',
      ...getColumnSearchProps('title', 'Name'),
    },
    {
      title: t('productPage.linkPackaging.packagingTable.description'),
      dataIndex: 'description',
      ...getColumnSearchProps('description', 'Description'),
    },
    {
      title: t('productPage.linkPackaging.packagingTable.subItemsCount'),
      dataIndex: 'subitemsCount',
      width: 130,
      render: (text, record) => (
        <InputNumber
          placeholder={t('productPage.subItems')}
          value={text}
          min={1}
          defaultValue={1}
          onChange={debounce(
            (subitemsCount) =>
              tempUpdatePackaging(record._id, { subitemsCount }),
            300,
          )}
        />
      ),
    },
    {
      title: t('productPage.linkPackaging.packagingTable.reportAble'),
      dataIndex: 'isReportable',
      width: 130,
      render: (text = true, record) => (
        <Switch
          checkedChildren="Yes"
          unCheckedChildren="No"
          checked={text}
          onChange={(isReportable) =>
            tempUpdatePackaging(record._id, { isReportable })
          }
        />
      ),
    },
    {
      title: t('productPage.linkPackaging.packagingTable.actions'),
      dataIndex: 'actions',
      width: 100,
      render: (_: any, record) => (
        <div className="text-center">
          <Button
            type="primary"
            size="small"
            onClick={() =>
              linkPackaging({
                packagingId: record._id,
                //@ts-ignore
                subitemsCount: record.subitemsCount,
                //@ts-ignore
                isReportable: record.isReportable,
              } as PackagingLink)
            }
          >
            Add
          </Button>
        </div>
      ),
    },
  ];
  const linkedColumns: ColumnProps<PackagingLink>[] = [
    {
      title: t('productPage.linkPackaging.linkedTable.name'),
      dataIndex: ['packaging', 'title'],
    },
    {
      title: t('productPage.linkPackaging.linkedTable.description'),
      dataIndex: ['packaging', 'description'],
    },
    {
      title: t('productPage.linkPackaging.linkedTable.subItemsCount'),
      dataIndex: 'subitemsCount',
      width: 130,
      onCell: (record) => ({
        className: record.isConflicting ? state.itemsCountColor : '',
      }),
      render: (text, record) => (
        <InputNumber
          placeholder={t('productPage.linkPackaging.linkedTable.reportable')}
          value={text}
          min={1}
          defaultValue={1}
          onChange={debounce(
            (subitemsCount) =>
              linkPackaging({
                packagingId: record.packaging._id,
                subitemsCount,
                isReportable: record.isReportable,
              } as PackagingLink),
            300,
          )}
        />
      ),
    },
    {
      title: t('productPage.linkPackaging.linkedTable.reportable'),
      dataIndex: 'isReportable',
      width: 130,
      // render: (text) => (text ? 'Yes' : 'No'),
      render: (text, record) => (
        <Switch
          checkedChildren={t('productPage.ok')}
          unCheckedChildren={t('productPage.noTxt')}
          checked={text}
          onChange={(isReportable) =>
            linkPackaging({
              packagingId: record.packaging._id,
              isReportable,
              subitemsCount: record.subitemsCount,
            } as PackagingLink)
          }
        />
      ),
    },
    {
      title: t('productPage.linkPackaging.linkedTable.actions'),
      dataIndex: 'actions',
      width: 100,
      render: (_: any, record) =>
        (!switchedMasterAccount(auth) &&
          record.companyId === auth?.user?.companyId) ||
        (switchedMasterAccount(auth) && record.companyId === hostCompanyId) ? (
          <div className="text-center">
            <Popconfirm
              title={t('productPage.popUnassignText')}
              onConfirm={() => removePackagingLink(record)}
              okText={t('productPage.unAssign')}
              okButtonProps={{ danger: true }}
              placement="topLeft"
            >
              <Button type="primary" size="small" danger>
                {t('productPage.removeText')}
              </Button>
            </Popconfirm>
          </div>
        ) : null,
    },
  ];

  useEffect(() => {
    if (visible) {
      setState((old) => ({
        ...old,
        isLoadingLinks: true,
        linksFetched: false,
        linkedIds: [],
      }));
      PackagingLinkService.find({
        query: {
          action: 'getLinkedPackaging',
          filters: {
            _id: { $in: products.map((item) => item?._id) },
          },
        },
      }).then(
        (res: PackagingLink[]) => {
          const hasConflict = res.some((item) => item.isConflicting);
          setState((old) => ({
            ...old,
            isLoadingLinks: false,
            itemsCountColor: hasConflict ? 'bg-gray-300' : 'bg-white',
            linksFetched: true,
            linkedIds: res.map((item) => item?._id?.split(':')?.[1] || ''),
          }));
          setLinkedPackagings(res);
        },
        (e: Error) => {
          message.error(t('productPage.linkPackaging.messages.fetching'));
          console.log('Error in fetching assigned packagings: ', e);
          setState((old) => ({ ...old, isLoadingLinks: false }));
        },
      );
    }
  }, [visible, debouncedRipple1, debouncedRipple2]);
  useEffect(() => {
    if (visible && state.linksFetched) {
      setState((old) => ({ ...old, isLoadingPackagings: true }));
      let filterQuery = {};

      for (const [key, value] of Object.entries<keyof Packaging>(filters)) {
        if (value && value.trim()) {
          //TODO: we need to remoe this key as it no longer exist in db schema
          if (key === 'createdByCompanyId') {
            filterQuery = Object.assign({}, filterQuery, {
              [key]: value,
            });
          } else {
            filterQuery = Object.assign({}, filterQuery, {
              [key]: { $search: value },
            });
          }
        }
      }
      PackagingsService.find({
        query: {
          $skip: skip,
          $limit: limit,
          ...filterQuery,
          _id: { $nin: state.linkedIds },
          isArchived: { $ne: true },
          isBulkPackaging: { $ne: true },
          reportingPeriodId: reportingPeriod?._id,
        },
      })
        .then(
          (res: Paginated<Packaging>) => setPackagings(res),
          (e: Error) => {
            console.log(t('productPage.linkPackaging.messages.fetching'), e);
            message.error(t('productPage.linkPackaging.messages.fetching'));
          },
        )
        .finally(() =>
          setState((old) => ({ ...old, isLoadingPackagings: false })),
        );
    }
  }, [visible, skip, limit, filters, state.linksFetched]);

  const closeModal = () => {
    const { shouldReload } = state;
    setState({
      isLoadingPackagings: false,
      isLoadingLinks: false,
      filterValues: {},
      ripple: false,
      itemsCountColor: 'bg-white',
      linksFetched: false,
      linkedIds: [] as string[],
      shouldReload: false,
    });
    setFilters({});
    setLinkedPackagings([]);
    setPackagings(INITIAL_STATE);
    handleClose(shouldReload);
  };
  return (
    <Modal
      title={t('productPage.modalText')}
      visible={visible}
      onCancel={closeModal}
      footer={null}
      width={1000}
      destroyOnClose
    >
      <div className="font-bold">{t('productPage.assign')}</div>
      {state.itemsCountColor !== 'bg-white' ? (
        <span className="font-bold text-red-400">
          {t('productPage.warningText')}
        </span>
      ) : null}
      <Table
        rowKey="_id"
        columns={linkedColumns}
        loading={state.isLoadingLinks}
        dataSource={linkedPackagings}
        className="mb-10"
        pagination={{
          defaultPageSize: 50,
          showSizeChanger: true,
          pageSizeOptions: ['50', '100', '250', '500'],
        }}
      />
      <div className="font-bold">{t('productPage.pkgAssign')}</div>
      <Button
        className="my-2"
        type="primary"
        onClick={createAndAssignNewPackaging}
      >
        New Packaging
      </Button>
      <Table
        rowKey="_id"
        columns={packagingColumns}
        dataSource={data}
        loading={state.isLoadingPackagings}
        pagination={{
          current: skip / limit + 1,
          total,
          pageSize: limit,
          showSizeChanger: true,
          pageSizeOptions: ['50', '100', '250', '500'],
          onShowSizeChange: (page, size = limit) => {
            localStorage.setItem('linkPackagings-pageSize', size.toString());
            setPackagings((old) => ({ ...old, limit: size }));
          },
          onChange: (page) =>
            setPackagings((old) => ({ ...old, skip: (page - 1) * limit })),
        }}
      />
    </Modal>
  );
};

export default LinkPackaging;
