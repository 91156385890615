import { Button, Popconfirm, Table, Tag, message } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsFillTrashFill, BsPencil } from 'react-icons/bs';
import { HiDuplicate } from 'react-icons/hi';
import { useLocation, useParams } from 'react-router-dom';

import { PackagingsService } from '../../api/services';
import { useActiveReportingPeriod } from '../../hooks/useActiveReportingPeriod';
import { useAuth } from '../../hooks/useAuth';
import { BulkPackaging, Paginated } from '../../types';
import {
  handleSorting,
  isMasterAccount,
  isObserverUser,
  setUpSorting,
  switchedMasterAccount,
} from '../../utils';
import { getFullName } from '../Packaging/getFullName';
import BulkPackagingEditor from './BulkPackagingEditor';
import BulkPackagingFilters, {
  BulkPackagingListFilters,
} from './BulkPackagingFilters';

const DEFAULT_LIMIT = +(localStorage.getItem('packagings-pageSize') || 50);
const INITIAL_STATE: Paginated<BulkPackaging> = {
  data: [],
  limit: DEFAULT_LIMIT,
  skip: 0,
  total: 0,
};
const defaultSorting = { title: 1 };

const hostCompanyId = localStorage.getItem('view-as') || '';

const getFiltersQuery = (filters: BulkPackagingListFilters) => {
  const { searchTerm, statuses, unAudited } = filters;
  const searchString = (searchTerm || '').trim();

  let query = {};
  if (statuses?.length) {
    query = Object.assign({}, query, { statuses });
  }
  if (unAudited) {
    query = Object.assign({}, query, { audited: false });
  }
  if (searchString) {
    query = Object.assign({}, query, {
      $multi_match: { $query: searchString },
    });
  }

  return query;
};

const verifyBulkPackagingIsComplete = (record: BulkPackaging) => {
  const { separableItems = [], title = '' } = record || {};
  if (!!title) {
    return separableItems.every(
      (item) =>
        item.description &&
        item.category &&
        item.neaPackagingForm &&
        item?.weight?.value > 0,
    );
  }
  return false;
};

const BulkPackagingList = () => {
  const [t] = useTranslation();
  const [packagings, setPackagings] = useState(INITIAL_STATE);
  const auth = useAuth();
  const [state, setState] = useState({
    isEditing: false,
    editedRecord: {} as BulkPackaging,
    sorts: {},
    filters: {},
    isLoading: false,
    ripple: false,
  });
  const { reportingPeriod, isViewOnlyMode } = useActiveReportingPeriod();
  const { limit, skip, total, data } = packagings;

  const removePackaging = (packagingId: string) => {
    PackagingsService.remove(packagingId).catch((error: Error) => {
      message.error(t('packagingPage.container.messages.remove'));
      console.log('Error in removing packaging: ', error);
    });
  };
  const duplicatePackaging = (packagingId: string) => {
    PackagingsService.create({
      action: 'duplicatePackaging',
      packagingId,
      reportingPeriodId: reportingPeriod?._id,
    }).then(
      (packaging: BulkPackaging) => {
        setState((old) => ({
          ...old,
          isEditing: true,
          readOnly: false,
          editedRecord: packaging,
        }));
      },
      (e: Error) => {
        console.log('Error in duplicating packaging: ', e);
        message.error('Could not create duplicate');
      },
    );
  };
  let columns: ColumnProps<BulkPackaging>[] = [
    {
      title: t('packagingPage.container.table.name'),
      dataIndex: 'title',
      render: (text) => (
        <div className="truncate" style={{ maxWidth: '400px' }} title={text}>
          {text}
        </div>
      ),
      ...setUpSorting(state.sorts, 'title', 1),
    },
    {
      title: t('packagingPage.container.table.status'),
      width: 150,
      dataIndex: 'statuses',
      className: 'capitalize',
      ...setUpSorting(state.sorts, 'statuses', 3),
      render: (text, record) =>
        text.map((status: string) => <Tag key={status}>{status}</Tag>),
      onCell: (record) => ({
        style: {
          backgroundColor: record?.statuses?.includes('redFlag')
            ? '#F4B183'
            : 'white',
        },
      }),
    },
    {
      title: t('packagingPage.container.table.action'),
      dataIndex: 'actions',
      render: (text, record) => (
        <div style={{ minWidth: '145px' }}>
          <Button
            type="text"
            onClick={() =>
              setState((old) => ({
                ...old,
                isEditing: true,
                readOnly: false,
                editedRecord: record,
              }))
            }
          >
            <BsPencil />
          </Button>

          <Popconfirm
            title={t('packagingPage.container.popMsg')}
            okText={t('packagingPage.ok')}
            placement="topLeft"
            onConfirm={() => {
              removePackaging(record._id);
            }}
          >
            <Button type="text">
              <BsFillTrashFill />
            </Button>
          </Popconfirm>
          <Popconfirm
            title="Do you really want to duplicate this packaging?"
            onConfirm={() => duplicatePackaging(record._id)}
          >
            <Button type="text">
              <HiDuplicate className="text-lg" />
            </Button>
          </Popconfirm>
        </div>
      ),
    },
    {
      title: t('packagingPage.container.table.lastChange'),
      dataIndex: 'updatedAt',
      render: (text) => (
        <div className="w-24">
          {text ? moment(text).format('DD MMM YYYY') : ''}
        </div>
      ),
      ...setUpSorting(state.sorts, 'updatedAt', 4),
    },
    {
      title: t('packagingPage.container.table.hasEvidence'),
      dataIndex: 'evidenceCount',
      ...setUpSorting(state.sorts, 'evidenceCount', 5),
      render: (text, record) => (
        <div className="w-20">
          {record.assessmentMethodId && (text > 0 || !!record.assessmentComment)
            ? 'Yes'
            : 'No'}
        </div>
      ),
      onCell: (record) => ({
        style: {
          backgroundColor:
            record.assessmentMethodId &&
            (record.evidenceCount > 0 || !!record.assessmentComment)
              ? '#C5E0B4'
              : 'white',
        },
      }),
    },
    {
      title: t('packagingPage.container.table.completed'),
      render: (text, record) => (
        <div className="w-20">
          {verifyBulkPackagingIsComplete(record) ? 'Yes' : 'No'}
        </div>
      ),
      onCell: (record) => ({
        style: {
          backgroundColor: verifyBulkPackagingIsComplete(record)
            ? '#C5E0B4'
            : 'white',
        },
      }),
    },
    {
      title: t('packagingPage.container.table.lastEditorName'),
      dataIndex: 'lastEditorName',
      ...setUpSorting(state.sorts, 'lastEditorName', 3),
      render: (_text, record) => (
        <div className="w-20">{getFullName(record.user)}</div>
      ),
    },
    {
      title: t('packagingPage.container.table.description'),
      dataIndex: 'description',
      render: (text) => (
        <div className="truncate" style={{ maxWidth: '300px' }} title={text}>
          {text}
        </div>
      ),
      ...setUpSorting(state.sorts, 'description', 2),
    },
  ];

  if (isObserverUser(auth) || isViewOnlyMode) {
    columns = columns.filter((item) => item.dataIndex !== 'actions');
  }

  useEffect(() => {
    let isMounted = true;
    setState((old) => ({ ...old, isLoading: true }));
    const filtersQuery = getFiltersQuery(state.filters);
    const $sort =
      Object.keys(state.sorts)?.length > 0 ? state.sorts : defaultSorting;
    PackagingsService.find({
      query: {
        $sort,
        $limit: limit,
        $skip: skip,
        isBulkPackaging: true,
        ...filtersQuery,
        reportingPeriodId: reportingPeriod?._id,
      },
    })
      .then(
        (res: Paginated<BulkPackaging>) => {
          if (isMounted) {
            setPackagings(res);
          }
        },
        (e: Error) => {
          console.log('Error in fetching packagings: ', { error: e });
          message.error(t('packagingPage.container.messages.fetching'));
        },
      )
      .finally(() => setState((old) => ({ ...old, isLoading: false })));

    return () => {
      isMounted = false;
    };
  }, [limit, skip, state.filters, state.sorts, state.ripple]);

  useEffect(() => {
    const handleCreate = (res: BulkPackaging) => {
      const companyId = switchedMasterAccount(auth)
        ? hostCompanyId
        : auth?.user?.companyId || '';
      if (!res?.companyId?.includes(companyId) || !res.isBulkPackaging) {
        return;
      }
      setPackagings((old) => ({
        ...old,
        data: [res, ...old.data],
        total: old.total + 1,
      }));
    };
    const handlePatch = (res: BulkPackaging) =>
      setPackagings((old) => ({
        ...old,
        data: old.data.map((item) => (item._id === res._id ? res : item)),
      }));

    const handleRemove = (res: BulkPackaging) =>
      setPackagings((old) => ({
        ...old,
        data: old.data.filter((item) => item._id !== res._id),
      }));

    PackagingsService.on('created', handleCreate);
    PackagingsService.on('patched', handlePatch);
    PackagingsService.on('removed', handleRemove);
    return () => {
      PackagingsService.off('created', handleCreate);
      PackagingsService.off('patched', handlePatch);
      PackagingsService.off('removed', handleRemove);
    };
  }, []);

  const query = decodeURIComponent(
    new URLSearchParams(useLocation().search).get('searchTerm') || '',
  );

  useEffect(() => {
    setState((old) => ({
      ...old,
      ripple: !state.ripple,
      filters: {
        searchTerm: query,
      },
    }));
  }, [query]);

  return (
    <div className="bg-white p-4">
      {state.isEditing ? (
        <BulkPackagingEditor
          editedRecord={state.editedRecord}
          handleClose={() =>
            setState((old) => ({
              ...old,
              isEditing: false,
              editedRecord: {} as BulkPackaging,
            }))
          }
          handleSave={(packaging = {} as BulkPackaging) =>
            setState((old) => ({
              ...old,
              editedRecord: { ...old.editedRecord, ...packaging },
            }))
          }
          readOnly={false}
        />
      ) : (
        <>
          <header>
            <div className="flex items-center">
              <div className="font-sans text-2xl font-bold md:text-3xl mr-auto">
                {t('Bulk packagings')}
              </div>
              {!isMasterAccount(auth) &&
              !isObserverUser(auth) &&
              !isViewOnlyMode ? (
                <Button
                  type="primary"
                  className="font-bold text-white uppercase mx-2"
                  onClick={() =>
                    setState((old) => ({
                      ...old,
                      isEditing: true,
                      editedRecord: {} as BulkPackaging,
                    }))
                  }
                >
                  {t('packagingPage.container.btnText')}
                </Button>
              ) : null}
            </div>
            <BulkPackagingFilters
              filters={state.filters}
              setFilters={(filters) => setState((old) => ({ ...old, filters }))}
            />
          </header>
          <Table
            rowKey="_id"
            columns={columns}
            dataSource={data}
            pagination={{
              total,
              pageSize: limit,
              current: skip / limit + 1,
              showSizeChanger: true,
              pageSizeOptions: ['50', '100', '250', '500'],
              onShowSizeChange: (page, size = limit) => {
                localStorage.setItem('packagings-pageSize', size.toString());
                setPackagings((old) => ({
                  ...old,
                  skip: (page - 1) * size,
                  limit: size,
                }));
              },
              onChange: (page, size = limit) =>
                setPackagings((old) => ({
                  ...old,
                  skip: (page - 1) * size,
                  limit: size,
                })),
            }}
            onChange={(a, b, sort = {}) => {
              // if ((sort as SorterResult<BulkPackaging>).field === 'linksCount') {
              //   let newOrder: SortOrder = null;
              //   let sortFunc = (a: any, b: any): number =>
              //     a.title < b.title ? -1 : 1;

              //   if (state.linksSort === null) {
              //     newOrder = 'descend';
              //     sortFunc = (a, b) =>
              //       (b.linksCount || 0) - (a.linksCount || 0);
              //   } else if (state.linksSort === 'descend') {
              //     newOrder = 'ascend';
              //     sortFunc = (a: any, b: any) =>
              //       (a.linksCount || 0) - (b.linksCount || 0);
              //   } else {
              //     newOrder = null;
              //   }
              //   setState((old) => ({ ...old, linksSort: newOrder }));
              //   setPackagings((old) => ({
              //     ...old,
              //     data: old.data.sort(sortFunc),
              //   }));
              // } else {
              handleSorting(
                sort as SorterResult<BulkPackaging>[],
                state.sorts,
                (sorts) => setState((old) => ({ ...old, sorts })),
              );
              // }
            }}
            loading={state.isLoading}
            scroll={{
              x: true,
              y: 'calc(100vh - 386px)',
            }}
          />
        </>
      )}
    </div>
  );
};

export default BulkPackagingList;
