import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Modal, Popconfirm, Spin, message } from 'antd';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ExportService } from '../../api/services';
import { useActiveReportingPeriod } from '../../hooks/useActiveReportingPeriod';
import { useAuth } from '../../hooks/useAuth';
import { isObserverUser } from '../../utils';
import { UploadedFile } from '../Packaging/edit-packaging/PackagingEditor/Components/Evidence';

interface ExportRecord {
  _id: string;
  status: 'done' | 'pending';
  createdAt: Moment;
  file: UploadedFile;
}
const DownloadAllData = () => {
  const { t } = useTranslation();
  const [isExporting, setIsExporting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [downloads, setDownloads] = useState([] as ExportRecord[]);
  const auth = useAuth();
  const { reportingPeriod } = useActiveReportingPeriod();

  const requestDownload = () => {
    ExportService.create({
      serviceName: 'downloadData',
      exportType: 'allRecords',
      filters: JSON.stringify({ reportingPeriodId: reportingPeriod?._id }),
    }).then(
      (res: ExportRecord) => {
        setDownloads((old) => [res, ...old]);
        message.info({
          type: 'info',
          content: t('dashboard.DownloadData.message'),
        });
      },
      (err: Error) =>
        console.log(t('dashboard.DownloadData.errors.exportingRecord'), err),
    );
  };

  const deleteExport = (id: string) => {
    setIsLoading(true);
    ExportService.remove(id)
      .then(
        (res: ExportRecord) => {
          setDownloads((old) => old.filter((item) => item._id !== res._id));
        },
        () => {
          message.error(t('dashboard.DownloadData.errors.deleteExport'));
        },
      )
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (isExporting) {
      setIsLoading(true);
      ExportService.find({
        query: { serviceName: 'downloadData', $sort: { createdAt: -1 } },
      })
        .then(
          (res: any) => {
            console.log('res: ', res);
            setDownloads(res.data);
          },
          (e: Error) => {
            console.log('Error in fetching exports: ', e);
            message.error(t('dashboard.DownloadData.errors.fetchingRecord'));
          },
        )
        .finally(() => setIsLoading(false));
    }
  }, [isExporting]);

  return (
    <>
      <Dropdown.Button
        type="primary"
        icon={<DownOutlined />}
        onClick={requestDownload}
        overlay={
          <Menu>
            <Menu.Item key="list" onClick={() => setIsExporting(true)}>
              {t('dashboard.DownloadData.listOfDownloads')}
            </Menu.Item>
          </Menu>
        }
      >
        {t('dashboard.DownloadData.downloadAllData')}
      </Dropdown.Button>

      <Modal
        title="List of downloads"
        visible={isExporting}
        onCancel={() => setIsExporting(false)}
        footer={
          <div className="flex justify-end px-2">
            <Button type="default" onClick={() => setIsExporting(false)}>
              {t('dashboard.DownloadData.close')}
            </Button>
          </div>
        }
        bodyStyle={{ padding: '24px' }}
      >
        <Spin spinning={isLoading}>
          {downloads.length > 0 ? (
            downloads.map((item) => (
              <div
                className="flex items-center justify-between s-hover-parent"
                key={item._id}
              >
                <div className="truncate">
                  {moment(item.createdAt).format('DD MMM YYYY - HH:mm')}
                </div>
                <div className="flex justify-between w-40">
                  {item.status === 'done' && (
                    <Button
                      type="link"
                      className="px-0"
                      href={item?.file?.url}
                      download
                    >
                      {t('dashboard.DownloadData.download')}
                    </Button>
                  )}
                  {item.status === 'pending' && (
                    <Button type="link" className="px-0" disabled>
                      {t('dashboard.DownloadData.pending')}
                    </Button>
                  )}
                  {item.status !== 'pending' && item.status !== 'done' && (
                    <Button type="link" className="px-0" disabled>
                      {t('dashboard.DownloadData.failed')}
                    </Button>
                  )}
                  {isObserverUser(auth) ? null : (
                    <Popconfirm
                      title="Are you sure you want to delete this export file?"
                      onConfirm={() => deleteExport(item._id)}
                      placement="topRight"
                      okText="Yes"
                      okButtonProps={{ danger: true }}
                      disabled={isLoading}
                    >
                      <Button
                        type="text"
                        className="text-red-500 s-hover-target"
                        disabled={isLoading}
                      >
                        {t('dashboard.DownloadData.delete')}
                      </Button>
                    </Popconfirm>
                  )}
                </div>
              </div>
            ))
          ) : (
            <div className="font-bold text-center text-gray-400">
              {t('dashboard.DownloadData.dowloadNotAvail')}
              <p>{t('dashboard.DownloadData.quickMessage')}</p>
            </div>
          )}
        </Spin>
      </Modal>
    </>
  );
};

export default DownloadAllData;
