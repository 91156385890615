import { useQuery } from 'react-query';

import { useAuth } from '../../../../../../hooks/useAuth';
import { User } from '../../../../../../types';
import { findUsers } from './findUsers';

export function useZWCUsers(options = {}) {
  const { user } = useAuth();
  if (user?.role.startsWith('company')) {
    return { data: [], usersById: {}, isLoading: false };
  }

  const { data, ...res } = useQuery(['users', { viewAs: 'zwc' }], findUsers, {
    staleTime: 5 * 60 * 1000,
    ...options,
  });

  const usersById = (data || []).reduce(
    (a: { [index: string]: User }, c: User) => ({ ...a, [c._id]: c }),
    {} as { [index: string]: User },
  );

  return { ...res, data, usersById };
}
